import { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Header from './Header';
import Projects from './pages/Projects';
import About from './pages/About';
import Works from './pages/Works';
import Contact from './pages/Contact';
import Work from './pages/Work';
import Project from './pages/Project';
import './App.css';

export default function App() {

    const [workData, setWorkData] = useState(null);
    const [projectData, setProjectData] = useState(null);
    const [aboutData, setAboutData] = useState(null);
    const [contactData, setContactData] = useState(null);

    return (
        <div className="App" onTouchStart={() => { return false }}>
            <Header />
            <Switch>

                <Route exact path="/works">
                    <Works
                        workData={workData}
                        setWorkData={setWorkData}
                    />
                </Route>

                <Route exact path="/projects">
                    <Projects
                        projectData={projectData}
                        setProjectData={setProjectData} />
                </Route>

                <Route exact path="/about">
                    <About
                        aboutData={aboutData}
                        setAboutData={setAboutData}
                    />
                </Route>

                <Route exact path="/contact">
                    <Contact
                        contactData={contactData}
                        setContactData={setContactData}
                    />
                </Route>

                <Route path="/work/:title">
                    <Work
                        type={'work'}
                        workData={workData}
                        setWorkData={setWorkData}
                    />
                </Route>

                <Route path="/project/:title">
                    <Work
                        type={'project'}
                        workData={projectData}
                        setWorkData={setProjectData}
                    />
                </Route>

                <Redirect from="*" to="/works" />

            </Switch>
        </div>
    );
}