import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import './Header.css';

export default function Header() {

    const [showNav, setShowNav] = useState(false);


    const handleNavButtonClick = () => {
        setShowNav(!showNav);
    }

    return (
        <div className="header" >
            <h1><Link to="/">Ray G. Brown</Link></h1>
            <div id="mobileNavButton">
                {!showNav &&
                    <div id="hamburger" onClick={() => setShowNav(!showNav)}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                }
                {showNav &&
                    <div id="cross" onClick={() => setShowNav(!showNav)}>
                        <div></div>
                        <div></div>
                    </div>
                }
                {showNav &&
                    <div id="mobileNav">
                        <p><Link to="/works">works</Link></p>
                        <p><Link to="/projects">projects</Link></p>
                        <p><Link to="/about">about</Link></p>
                        <p><Link to="/contact">contact</Link></p>
                    </div>
                }
            </div>
        </div>
    )
};