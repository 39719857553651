import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import './Works.css';

const query = `{
    workCollection {
        items {
            title
            year
            dimensions
            materials
            description {
                json
            }
            imagesCollection {
                items {
                    url
                }
            }
        }
    }
}`

export default function Works(props) {

    const {
        workData,
        setWorkData
    } = props;

    useEffect(() => {
        if (!workData) {

            window.fetch(`https://graphql.contentful.com/content/v1/spaces/1rl1jlxxxe6l/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // Authenticate the request
                    Authorization: "Bearer 45CfDXmLKyusG7xKSwYwBDNTqyV0EcQdgWKMi6MxNPQ",
                },
                // send the GraphQL query
                body: JSON.stringify({ query }),
            })
                .then((response) => response.json())
                .then(({ data, errors }) => {
                    if (errors) {
                        console.error(errors);
                    };

                    console.log(data.workCollection.items);
                    let sortedData = data.workCollection.items.sort((a, b) => b.year - a.year);

                    setWorkData(sortedData);
                });

        };
    }, []);

    if (workData) {

        return (
            <div className="works" >
                {workData && workData.map((elem, i) => {
                    return (
                        <div className="workContainer" key={elem.title}>
                            <div className="workImageContainer">
                                <Link to={{
                                    pathname: `/work/${elem.title}`
                                }}>
                                    <img onLoad={(e) => { e.target.parentNode.parentNode.parentNode.style.opacity = 1 }} src={elem.imagesCollection.items[0].url} alt={elem.title} />
                                </Link>
                            </div>
                            <div className="workInfoContainer">
                                <p><span>{elem.title},</span>&nbsp;&nbsp;{elem.year}</p>
                                <p>{elem.materials}</p>
                                <p>{elem.dimensions}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        )

    } else {
        return <div className="loadingContainer">
            Loading...
        </div>
    };
};