import {useState, useEffect} from "react";
import {useLocation, useRouteMatch, Link} from 'react-router-dom';
import * as contentful from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import './About.css';

const query = `{
    textCollection {
        items {
            title
            text {
                json
            }
        }
    }
}`

export default function About(props) {


    const {
        aboutData,
        setAboutData
    } = props;

    const locationData = useLocation();

    const [viewCV, setViewCV] = useState(false);

    useEffect(() => {
        window.fetch(`https://graphql.contentful.com/content/v1/spaces/1rl1jlxxxe6l/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // Authenticate the request
                Authorization: "Bearer 45CfDXmLKyusG7xKSwYwBDNTqyV0EcQdgWKMi6MxNPQ",
            },
            // send the GraphQL query
            body: JSON.stringify({query}),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
            if (errors) {
                console.error(errors);
            };

            // rerender the entire component with new data
            console.log(data.textCollection.items);
            let sortedData = {};
            for (const elem of data.textCollection.items) {
                // if (locationData.pathname.includes(elem.title.toLowerCase())) {
                    elem.text = documentToHtmlString(elem.text.json);
                    sortedData[elem.title] = elem;
                    console.log('sorted', sortedData);
                    // break;
                // };
            };
            setAboutData(sortedData);
        });
    }, []);

    if (aboutData) {

        return (
            <div className="about">
                <div
                    className="aboutText"
                    dangerouslySetInnerHTML={{ __html: aboutData.About.text }}
                ></div>
                <p
                    className="cvButton"
                    onClick={() => setViewCV(!viewCV)}
                    style={{
                        // marginBottom: viewCV ? '20px' : '10%'
                    }}
                >View CV</p>
                {viewCV &&
                    <div
                        className="cv"
                        dangerouslySetInnerHTML={{ __html: aboutData.CV.text }}
                    ></div>
                }
            </div>
        )

    } else {
        return <div className="loadingContainer">
            Loading...
        </div>
    };

};