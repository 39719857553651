import { useState, useEffect, useRef, ReactNode } from "react";
import { useLocation, useRouteMatch, Link } from 'react-router-dom';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { Carousel } from 'react-responsive-carousel';
import './Work.css';
import 'react-responsive-carousel/lib/styles/carousel.css';

const Img = ({ url }) => {

    const [loaded, setLoaded] = useState(false);
    const [cursorPos, setCursorPos] = useState(null);

    return (
        <div
            className="workImg"
            style={{
                cursor: cursorPos === 'left' ? 'w-resize' : 'e-resize'
            }}
        >
            {!loaded &&
                <p>Loading...</p>
            }
            <img
                src={url}
                style={{
                    opacity: loaded ? 1 : 0
                }}
                onLoad={() => setLoaded(true)}
            />
        </div>
    );

};

export default function Work(props) {

    const {
        workData,
        setWorkData
    } = props;

    const locationData = useLocation();
    let q = locationData.pathname.split("/")[1];

    const [index, setIndex] = useState(null);
    const [showArrows, setShowArrows] = useState(true);

    const query = q === 'work' ? `{
        workCollection {
            items {
                title
                year
                dimensions
                materials
                description {
                    json
                }
                imagesCollection {
                    items {
                        url
                    }
                }
            }
        }
    }` :
        `{
        projectCollection {
            items {
                title
                year
                location
                description {
                    json
                }
                imagesCollection {
                    items {
                        url
                    }
                }
            }
        }
    }`;

    const calculateIndex = () => {
        let x = workData.findIndex((elem) => locationData.pathname.includes(elem.title));
        setIndex(x);
    };

    const prevWorkArrow = (clickHandler, hasPrev) => {

        return (
            <div
                className="workArrows"
                style={{
                    opacity: hasPrev ? 1 : 0,
                    pointerEvents: hasPrev ? 'auto' : 'none'
                }}
                onClick={clickHandler}
            >previous</div>
        );

    };

    const nextWorkArrow = (clickHandler, hasNext) => {

        return (
            <div
                className="workArrows"
                style={{
                    opacity: hasNext ? 1 : 0,
                    pointerEvents: hasNext ? 'auto' : 'none'
                }}
                onClick={clickHandler}
            >next</div>
        );

    };

    const prevImgArrow = (clickHandler) => {

        return (
            <div
                className="imgArrows"
                style={{
                    opacity: showArrows ? 1 : 0,
                    pointerEvents: showArrows ? 'auto' : 'none',
                }}
                onClick={clickHandler}
            >
                <span>&lt;</span>
            </div>
        );

    };

    const nextImgArrow = (clickHandler) => {

        return (
            <div
                className="imgArrows"
                style={{
                    opacity: showArrows ? 1 : 0,
                    pointerEvents: showArrows ? 'auto' : 'none',
                }}
                onClick={clickHandler}
            ><span>&gt;</span></div>
        );

    };

    const indicators = (clickHandler, isSelected, index) => {

        return (
            <div
                className="indicator"
                style={{
                    backgroundColor: isSelected ? 'white' : 'transparent',
                    transform: isSelected ? 'scale(1.2)' : 'scale(1)'
                }}
                onClick={clickHandler}
            ></div>
        )

    };

    useEffect(() => {

        if (workData) {
            calculateIndex();
        } else {
            window.fetch(`https://graphql.contentful.com/content/v1/spaces/1rl1jlxxxe6l/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer 45CfDXmLKyusG7xKSwYwBDNTqyV0EcQdgWKMi6MxNPQ",
                },
                body: JSON.stringify({ query }),
            })
                .then((response) => response.json())
                .then(({ data, errors }) => {
                    if (errors) {
                        console.error(errors);
                    };
                    console.log(data);
                    let sortedData;

                    if (q === 'work') {
                        console.log('q in if', q);
                        sortedData = data.workCollection.items.sort((a, b) => b.year - a.year)

                    } else sortedData = data.projectCollection.items.sort((a, b) => b.year - a.year);
                    setWorkData(sortedData);
                });
        };

    }, [q]);

    useEffect(() => {
        if (workData) {
            calculateIndex();
        };
    }, [workData]);

    if (workData && (index || index === 0)) {

        return (
            <Carousel
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                transitionTime={0}
                selectedItem={index}
                swipeable={false}
                renderArrowPrev={(clickHandler, hasPrev) => { return prevWorkArrow(clickHandler, hasPrev) }}
                renderArrowNext={(clickHandler, hasNext) => { return nextWorkArrow(clickHandler, hasNext) }}
            >
                {workData.map(elem => {

                    const {
                        title,
                        imagesCollection,
                        materials,
                        dimensions,
                        description,
                        year
                    } = elem;

                    return (

                        <div className="work" >
                            <div
                                id="workImgContainer"
                            // onMouseEnter={() => setShowArrows(true)}
                            // onMouseLeave={() => setShowArrows(false)}
                            >
                                <Carousel
                                    infiniteLoop
                                    showThumbs={false}
                                    showStatus={true}
                                    showIndicators={false}
                                    useKeyboardArrows={true}
                                    transitionTime={0}
                                    swipeable={false}
                                    onSwipeStart={(e) => e.stopPropagation()}
                                    renderArrowPrev={(clickHandler, hasPrev) => { return prevImgArrow(clickHandler) }}
                                    renderArrowNext={(clickHandler, hasNext) => { return nextImgArrow(clickHandler) }}
                                // renderIndicator={(clickHandler, isSelected, index) => { return indicators(clickHandler, isSelected, index) }}
                                >
                                    {imagesCollection?.items?.map(img => {
                                        return (
                                            <Img
                                                url={img.url}
                                            />
                                        )
                                    })}
                                </Carousel>
                            </div>

                            <div id="workInfoContainer">
                                <div id="workTitle">
                                    <p><span>{title}</span>,&nbsp;{year}</p>
                                    <p>{materials}</p>
                                    <p>{dimensions}</p>
                                </div>
                                <div id="workDescription" dangerouslySetInnerHTML={description ? { __html: documentToHtmlString(description.json) } : null}></div>
                            </div>
                        </div>
                    )

                })}
            </Carousel>
        )

    } else {
        return <div className="loadingContainer">
            Loading...
        </div>
    };

};
