import {useState, useEffect} from "react";
import {useLocation, useRouteMatch, Link} from 'react-router-dom';
import * as contentful from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import './Contact.css';

const query = `{
    textCollection {
        items {
            title
            text {
                json
            }
        }
    }
}`

export default function Contact(props) {

    const locationData = useLocation();

    const {
        contactData,
        setContactData
    } = props;

    useEffect(() => {
        if (!contactData) {
            window.fetch(`https://graphql.contentful.com/content/v1/spaces/1rl1jlxxxe6l/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // Authenticate the request
                    Authorization: "Bearer 45CfDXmLKyusG7xKSwYwBDNTqyV0EcQdgWKMi6MxNPQ",
                },
                // send the GraphQL query
                body: JSON.stringify({query}),
            })
            .then((response) => response.json())
            .then(({ data, errors }) => {
                if (errors) {
                    console.error(errors);
                };

                // rerender the entire component with new data
                console.log(data.textCollection.items);
                let sortedData;
                for (const elem of data.textCollection.items) {
                    if (locationData.pathname.includes(elem.title.toLowerCase())) {
                        elem.text = documentToHtmlString(elem.text.json);
                        sortedData = elem;
                        break;
                    };
                };
                setContactData(sortedData);
            });
        }
    }, []);

    if (contactData) {

        return (
            <div
                className="contact"
                dangerouslySetInnerHTML={{ __html: contactData.text }}
            >
            </div>
        )

    } else {
        return <div className="loadingContainer">
            Loading...
        </div>
    };

};