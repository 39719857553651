import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import './Works.css';

const query = `{
    projectCollection {
        items {
            title
            year
            location
            description {
                json
            }
            imagesCollection {
                items {
                    url
                }
            }
        }
    }
}`

export default function Projects(props) {

    const {
        projectData,
        setProjectData
    } = props;

    useEffect(() => {
        window.fetch(`https://graphql.contentful.com/content/v1/spaces/1rl1jlxxxe6l/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // Authenticate the request
                Authorization: "Bearer 45CfDXmLKyusG7xKSwYwBDNTqyV0EcQdgWKMi6MxNPQ",
            },
            // send the GraphQL query
            body: JSON.stringify({query}),
        })
        .then((response) => response.json())
        .then(({ data, errors }) => {
            if (errors) {
                console.error(errors);
            };

            // rerender the entire component with new data
            console.log(data.projectCollection.items);
            let sortedData = data.projectCollection.items.sort((a,b) => b.year - a.year);

            setProjectData(sortedData);
        });
    }, []);

    if (projectData) {

        return ( <div className="works" >
                {projectData && projectData.map(elem => {
                    return <div className="workContainer" key={elem.title}>
                        <div className="workImageContainer">
                            <Link to={{
                                pathname: `/project/${elem.title}`
                            }}>
                                <img onLoad={(e) => { e.target.parentNode.parentNode.parentNode.style.opacity = 1}} src={elem.imagesCollection.items[0].url} alt={elem.title}/>
                            </Link>
                        </div>
                        <div className="workInfoContainer">
                            <p><span>{elem.title},</span>&nbsp;&nbsp;{elem.year}</p>
                            <p>{elem.location}</p>
                        </div>
                    </div>
                })}
                <div className="workContainer">
                    <div className="workImageContainer">
                        <a>
                            <img style={{width: '100%'}} src="https://images.unsplash.com/photo-1601662528567-526cd06f6582?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE1fHx8ZW58MHx8fHw%3D&w=1000&q=80"/>
                        </a>
                    </div>
                    <div className="workInfoContainer">
                        <p></p>
                        <p></p>
                    </div>
                </div>
            </div>
        )

    } else {
        return <div className="loadingContainer">
            Loading...
        </div>
    };

};